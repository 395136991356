import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useUsersList() {
  const userListTable = ref([])

  const tableColumns = [
    { text: 'ユーザID', value: 'userId' },
    { text: 'パスワード', value: 'password' },
    { text: 'ログイン画面', value: 'mainSc' },
    { text: 'IPアドレス制限', value: 'ipLimit' },
    { text: '閲覧範囲', value: 'sc', sortable: false },
    {
      text: 'アクション',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const selectedRows = ref([])

  // fetch data
  const fetchUsers = () => {
    store
      .dispatch('app-user/fetchUsers', {
        params: {
        },
      })
      .then(response => {
        userListTable.value = response.data.result.data.users

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        this.$router.push({ name: 'error-login' })
      })
  }

  watch([searchQuery, roleFilter, planFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchUsers()
  })

  return {
    userListTable,
    tableColumns,
    searchQuery,
    roleFilter,
    planFilter,
    statusFilter,
    totalUserListTable,
    loading,
    options,
    selectedRows,
    fetchUsers,
  }
}
