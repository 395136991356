var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('user-list-add-new',{ref:"child",attrs:{"role-options":_vm.roleOptions,"user-info":_vm.userInfo,"selected-values":_vm.selectedValues,"user-list-table":_vm.userListTable},on:{"refetch-data":_vm.fetchUsers},model:{value:(_vm.isAddNewUserSidebarActive),callback:function ($$v) {_vm.isAddNewUserSidebarActive=$$v},expression:"isAddNewUserSidebarActive"}}),_c('v-card',[_c('v-divider',{staticClass:"mt-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4 me-3",staticStyle:{"text-transform":"none"},attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.onClickAddNewUser()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("ユーザ追加")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.userListTable,"options":_vm.options,"loading":_vm.loading,"items-per-page":10},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_vm._v(" "+_vm._s(item.userId)+" ")])])]}},{key:"item.ipLimit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[(item.ipLimit === 1)?_c('p',[_vm._v(" あり ")]):_vm._e(),(item.ipLimit === 0)?_c('p',[_vm._v(" なし ")]):_vm._e()])])]}},{key:"item.sc",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize"},_vm._l((item.sc.split('_')),function(code){return _c('div',{key:code},[_c('p',[_vm._v(_vm._s(code))])])}),0)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"me-3",staticStyle:{"text-transform":"none"},attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.onClickEdit(item)}}},[_vm._v(" 編集 "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiBookEditOutline)+" ")])],1),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.onClickDeleteDialog(item)}}},[_vm._v(" 削除 "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" 確認 ")]),_c('v-card-text',[_vm._v("このユーザーを削除してよろしいでしょうか?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.isDialogVisible = false}}},[_vm._v(" いいえ ")]),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"color":"success"},on:{"click":function($event){return _vm.onClickDelete()}}},[_vm._v(" はい ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }