<template>
  <div id="user-list">
    <!-- app drawer -->
    <user-list-add-new
      ref="child"
      v-model="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :user-info="userInfo"
      :selected-values="selectedValues"
      :user-list-table="userListTable"
      @refetch-data="fetchUsers"
    ></user-list-add-new>

    <!-- list filters -->
    <v-card>
      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            style="text-transform: none"
            @click.stop="onClickAddNewUser()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>ユーザ追加</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="userListTable"
        :options.sync="options"
        :loading="loading"
        :items-per-page="10"
      >
        <!-- name -->
        <template #[`item.userId`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <!-- <router-link
                :to="{ name : 'apps-user-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              > -->
              {{ item.userId }}
              <!-- </router-link> -->
              <!-- <small>@{{ item.username }}</small> -->
            </div>
          </div>
        </template>

        <!-- ipLimit -->
        <template #[`item.ipLimit`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <p v-if="item.ipLimit === 1">
                あり
              </p>
              <p v-if="item.ipLimit === 0">
                なし
              </p>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.sc`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">
              <div
                v-for="code in item.sc.split('_')"
                :key="code"
              >
                <p>{{ code }}</p>
              </div>
            </span>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-btn
            color="primary"
            class="me-3"
            style="text-transform: none"
            @click.stop="onClickEdit(item)"
          >
            編集
            <v-icon
              dark
              right
            >
              {{ icons.mdiBookEditOutline }}
            </v-icon>
          </v-btn>
          <v-btn
            color="secondary"
            style="text-transform: none"
            @click.stop="onClickDeleteDialog(item)"
          >
            削除
            <v-icon
              dark
              right
            >
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="isDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          確認
        </v-card-title>
        <v-card-text>このユーザーを削除してよろしいでしょうか?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            outlined
            style="text-transform: none"
            @click="isDialogVisible = false"
          >
            いいえ
          </v-btn>
          <v-btn
            color="success"
            style="text-transform: none"
            @click="onClickDelete()"
          >
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPlus, mdiDeleteOutline, mdiBookEditOutline } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import UserListAddNew from './UserListAddNew.vue'
import userStoreModule from './userStoreModule'

import useUsersList from './useUsersList'

export default {
  components: {
    UserListAddNew,
  },
  mounted() {
    this.$dateRangePickerDisplayFlag = false
    this.$datePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false
  },
  methods: {
    onClickAddNewUser() {
      this.isAddNewUserSidebarActive = true
      this.userInfo = {
        id: '',
        userId: '',
        password: '',
        siteId: '',
        ipLimit: '1',
      }
      this.selectedValues.splice(0)
      this.$refs.child.resetFrom()
    },
    onClickEdit(item) {
      this.isAddNewUserSidebarActive = true
      this.userInfo = {
        id: item.id,
        userId: item.userId,
        password: item.password,
        siteId: String(item.siteId),
        ipLimit: String(item.ipLimit),
      }

      console.log(this.userInfo)
      this.selectedValues = item.scIds
      this.$refs.child.clearMessage()
    },
    onClickDeleteDialog(item) {
      this.deleteIndex = this.userListTable.indexOf(item)

      this.userInfo = {
        id: item.id,
        userId: item.userId,
        password: item.password,
        siteId: String(item.siteId),
        ipLimit: String(item.ipLimit),
        siteIds: item.scIds,
      }
      this.isDialogVisible = true
    },
    onClickDelete() {
      const oper = 'del'
      const id = String(this.userInfo.id)
      const siteId = String(this.userInfo.siteId)
      const siteIds = this.userInfo.scIds
      store
        .dispatch('app-user/editUser', {
          params: {
            id,
            siteId,
            siteIds,
            oper,
          },
        })
        .then(() => {
          this.userListTable.splice(this.deleteIndex, 1)
          this.isDialogVisible = false
        })
        .catch(error => {
          console.log(error)
          this.$router.push({ name: 'error-login' })
        })
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      userListTable,
      tableColumns,
      loading,
      options,
      selectedRows,

      fetchUsers,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
    } = useUsersList()

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { title: 'PhnomPenh', value: '2' },
      { title: 'Binh Duong Canary', value: '3' },
      { title: 'Long Bien', value: '8' },
      { title: 'Binh Tan', value: '11' },
      { title: 'Tan Phu Celadon', value: '12' },
      { title: 'Taman Maluri', value: '14' },
      { title: 'Ha Dong', value: '15' },
      { title: 'Le Chan', value: '16' },
    ]

    const userInfo = {
      id: '',
      userId: '',
      password: '',
      siteId: '',
      ipLimit: '',
    }
    const selectedValues = ref([])
    const deleteIndex = ref()
    const isDialogVisible = ref(false)

    return {
      isDialogVisible,
      deleteIndex,
      userListTable,
      tableColumns,
      roleOptions,
      loading,
      options,
      isAddNewUserSidebarActive,
      userInfo,
      selectedValues,
      selectedRows,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      fetchUsers,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiBookEditOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
